import React, { FC, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  PageLayout,
  HomePageContent,
  HeroBanner,
  HomePageSlider,
} from 'components';

export interface SeoType {
  metaDescription?: string;
  metaTitle: string;
  shareImage?: {
    alternativeText?: string;
    publicURL?: string;
    childImageSharp: {
      gatsbyImageData: {
        backgroundColor: string;
        height: number;
        width: number;
        images: {
          fallback: {
            sizes: string;
            src: string;
            srcSet: string;
          };
          sources: {
            sizes: string;
            srcSet: string;
            type: string;
          }[];
        };
      };
    };
  };
}
interface HomePageTypes {
  data: {
    title: string;
    seo: SeoType;
  };
}
const query = graphql`
  query HomePageQuery {
    HomePage: strapiHomePage {
      title
      quotation {
        content
        id
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
    }
  }
`;

const IndexPage: FC<HomePageTypes> = () => {
  const { HomePage } = useStaticQuery(query);
  const { title, seo } = HomePage;
  useEffect(() => {
    document.body.classList.add('Home-page');
    return () => {
      document.body.classList.remove('Home-page');
    };
  }, []);
  const quotation = HomePage.quotation.content;
  return (
    <PageLayout
      title={title}
      quotation={quotation}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
    >
      <HeroBanner />
      <HomePageContent />
      <HomePageSlider />
    </PageLayout>
  );
};

export default IndexPage;
